import React, { useState, useEffect } from "react";
import './Warehouse.css';
import small_logo from "../../logo192.png";
import Cookies from 'js-cookie';

import sushi1 from '../../images/sushi1.png';
import sushi2 from '../../images/sushi2.png';
import sushi3 from '../../images/sushi3.png';
import sushi4 from '../../images/sushi4.png';
import sushi5 from '../../images/sushi5.png';

export const Warehouse = () => {
    const [token] = useState(Cookies.get('session'));
    const [products, setProducts] = useState([]);  // Add this line

    useEffect(() => {
        const getProducts = async () => {
            const request = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain',
                    'session': token
                }
            }
            fetch('/auth/warehouse', request)
                .then(response => response.json())
                .then(data => {
                    setProducts(data);  // And this line
                })
                .catch(error => console.error('Error:', error));
        };

        getProducts();
    }, []);

    return (
        <div className="warehouse">
            <img src={small_logo} alt="logo" className="logo"/>
            <div className="shopTitle">
                <h1>List of all Sushi</h1>
            </div>
            {products.map(product => (
                <div key={product.id} className="product">
                    <h2>{product.name}</h2>
                    <p>Price: {product.price}</p>
                    <img src={product.productImage === 'sushi1.png' ? sushi1 :
                        product.productImage === 'sushi2.png' ? sushi2 :
                        product.productImage === 'sushi3.png' ? sushi3 :
                        product.productImage === 'sushi4.png' ? sushi4 :
                        sushi5} alt={product.name} />
                </div>
            ))}
        </div>
    );
}