import React, {useContext, useEffect, useState} from "react";
import { CartContext } from "../../CartConetext";
import './Cart.css';
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

import sushi1 from '../../images/sushi1.png';
import sushi2 from '../../images/sushi2.png';
import sushi3 from '../../images/sushi3.png';
import sushi4 from '../../images/sushi4.png';
import sushi5 from '../../images/sushi5.png';

export const Cart = () => {
    const { cart } = useContext(CartContext);

    const uniqueItems = cart.reduce((acc, item) => {
        const existingItem = acc.find(i => i.id === item.id);
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            item.quantity = 1;
            acc.push(item);
        }
        return acc;
    }, []);

    return (
        <div>
        <div className="Title">
                <h1>Cart</h1>
        </div>
            <div className="cart">
                {uniqueItems.length === 0 ? (
                    <div className="Title">
                        <h4>Your cart looks empty!</h4>
                        <h4>Maybe add some Sushi :)</h4>
                    </div>
                ) : (
                    uniqueItems.map((item, index) => (
                        <div className="cartItem" key={index}>
                            <h2>{item.name}</h2>
                            <p>{item.price}€</p>
                            <p>Quantity: {item.quantity}</p>
                            <img src={item.productImage === 'sushi1.png' ? sushi1 :
                                item.productImage === 'sushi2.png' ? sushi2 :
                                item.productImage === 'sushi3.png' ? sushi3 :
                                item.productImage === 'sushi4.png' ? sushi4 :
                                sushi5} alt={item.name}/>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}