import React, { useState } from 'react';
import './AuthForm.css';
import small_logo from "./logo192.png";
import { useNavigate } from 'react-router-dom';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {username: name, password: password
                })
        }
        fetch('/auth/register', request)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) });
                }
                return response;
            })
            .then(response => {

            navigate('/login', { state: { message: 'Please login with you new account!' } });

        })
            .catch(error => {
                console.error('Error:', error.message);
                setErrorMsg(error.message);
            });

    };

    return (
        <div>
            <img src={small_logo} alt="logo" className="logo"/>
            <div className="Title">
                <h1>Register</h1>
                {errorMsg ? <p className="transfer">{errorMsg}</p> : <p>Please fill out this form to create an account</p>}
            </div>
            <form className="auth-form" onSubmit={handleSubmit}>
                <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" required/>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email"
                       required/>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)}
                       placeholder="Password" required/>
                <button type="submit">Register</button>
            </form>
        </div>
    );
}

export default Register;