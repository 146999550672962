import React, { useContext, useState, useEffect } from "react";
import './Shop.css';
import { CartContext } from "../../CartConetext";
import small_logo from "../../logo192.png";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

//TODO this is ugly lmao
import sushi1 from '../../images/sushi1.png';
import sushi2 from '../../images/sushi2.png';
import sushi3 from '../../images/sushi3.png';
import sushi4 from '../../images/sushi4.png';
import sushi5 from '../../images/sushi5.png';

export const Shop = () => {
    const { addToCart } = useContext(CartContext);
    const [clicked, setClicked] = useState(null);
    //const navigate = useNavigate();
    const [token, setToken] = useState(Cookies.get('session'));
	const [products, setProducts] = useState([]);  // Add this line
    useEffect(() => {
        const getProducts = async () => {
            const request = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'text/plain',
                    'session': token
                }
            }
            fetch('/auth/shop', request)
                .then(response => response.json())
                .then(data => {
                    setProducts(data);
                })
                .catch(error => console.error('Error:', error));
        };

        getProducts();
    }, []);

    const handleAddToCart = (product) => {
        addToCart(product);
        setClicked(product.id);
        setTimeout(() => setClicked(null), 100); // remove feedback after 2 seconds
    };

    return (
        <div className="shop">
            <img src={small_logo} alt="logo" className="logo"/>
            <div className="shopTitle">
                <h1>Lecker Sushi ohh boii</h1>
                <p>Choose your favourite sushi from the best Sushi-Shop in Graz!</p>
            </div>
            <div className="shopItems">
                {products.map(product => (
                    <div className="shopItem" key={product.id}>
                        <img src={product.productImage === 'sushi1.png' ? sushi1 :
                            product.productImage === 'sushi2.png' ? sushi2 :
                            product.productImage === 'sushi3.png' ? sushi3 :
                            product.productImage === 'sushi4.png' ? sushi4 :
                            sushi5} alt={product.name}/>
                        <h2>{product.name}</h2>
                        <p>{product.price}€</p>
                        <button
                            onClick={() => handleAddToCart(product)}
                            style={clicked === product.id ? {backgroundColor: 'green'} : {}}
                        >
                            Add to Cart
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}