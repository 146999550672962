import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { CartProvider } from './CartConetext';
import Login from './Login';
import Register from './Register';
import NavigationMenu from './NavigationMenu';
import logo from './logo.png';
import './App.css';
import {Cart} from './pages/cart/cart';
import {Shop} from './pages/shop/shop';
import {Warehouse} from "./pages/warehouse/warehouse";
import { SessionContext } from './SessionContext';


function App() {

    const [isCookieSet, setIsCookieSet] = useState(false);

    useEffect(() => {
        const checkCookie = setInterval(() => {
            const cookie = document.cookie.split('; ').find(row => row.startsWith('session='));
            setIsCookieSet(!!cookie);
        }, 1000); // Check for changes in the cookie every second

        return () => clearInterval(checkCookie); // Clean up the interval on unmount
    }, []);

    return (
        <div className={"App"}>
            <CartProvider>
                <Router>
                    <SessionContext.Provider value={{ isCookieSet, setIsCookieSet }}>
                        <NavigationMenu/>
                        <Routes>
                            <Route path="/" element={<div className="logo-container">
                                <img src={logo} alt="logo" className="logo-home"/>
                            </div>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/cart" element={<Cart/>}/>
                            <Route path="/shop" element={<Shop/>}/>
                            <Route path="/warehouse" element={<Warehouse/>}/>
                        </Routes>
                    </SessionContext.Provider>
                </Router>
            </CartProvider>
        </div>
    );
}

export default App;