import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './NavigationMenu.css';
import { SessionContext } from './SessionContext';

function NavigationMenu() {

    const { isCookieSet, setIsCookieSet } = useContext(SessionContext);

    const logout =  () => {
        setIsCookieSet(false);
        document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; //Removing cookie by setting it to expired
    }

    return (
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/register">Register</Link></li>
                <li><Link to="/shop">Sushi Shop</Link></li>
                <li><Link to="/cart">Cart</Link></li>
                {isCookieSet && (
                    <li><Link to="/" onClick={logout}>
                        Logout
                    </Link></li>)}
            </ul>
        </nav>
    );
}

export default NavigationMenu;