import React, { useState } from 'react';
import './AuthForm.css';
import small_logo from "./logo192.png";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setName] = useState('');
    const [password, setPassword] = useState('');
    const location = useLocation();
    const message = location.state?.message;
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request ={
            method: 'POST',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        }
        fetch('/auth/login', request)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) });
                }
                return response;
            })
            .then(response =>
                response.text())
            .then(token => {
                document.cookie = `session=${token}`;
				navigate('/shop', { state: { message: 'Logged in!' } });
            })
            .catch(error => {
                console.error('Error:', error.message);
                setErrorMsg(error.message);
                //alert(error.message);
            });
    };

    return (
        <div>
            <img src={small_logo} alt="logo" className="logo"/>
            <div className="Title">
                <h1>Login</h1>
                {message ? <p>{message}</p> : <p>Please fill in your credentials</p>}
                {errorMsg && <p className="transfer">{errorMsg}</p>}
            </div>
            <form className="auth-form" onSubmit={handleSubmit}>
                <input type="text" value={username} onChange={e => setName(e.target.value)} placeholder="Name" required/>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)}
                       placeholder="Password"
                       required/>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;